@keyframes marquee-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
.animate-marquee-left {
  animation: marquee-left var(--duration, 40s) linear infinite;
}
@keyframes marquee-up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - var(--gap)));
  }
}
.animate-marquee-up {
  animation: marquee-up var(--duration, 40s) linear infinite;
}

@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0)
      scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1))
      rotate(var(--tw-enter-rotate, 0));
  }
}
@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0)
      scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1))
      rotate(var(--tw-exit-rotate, 0));
  }
}
.direction-reverse {
  animation-direction: reverse;
}
.gap-\[1rem\] {
  gap: 1rem;
}
.\[--gap\:1rem\] {
  --gap: 1rem;
}
.group:hover .group-hover\:\[animation-play-state\:paused\] {
  animation-play-state: paused;
}
